<template>
  <div class="wrapper">
    <header class="move-up">
      <div>
        <h2>Panel administracyjny - <span>Zarządzanie</span></h2>
      </div>
    </header>
    <section class="wrapper">
      <header class="my-4">
        <button class="btn base" @click="addNewApartment">
          Dodaj nowe mieszkanie
        </button>
        <button class="btn base ml-2" @click="logoutFromApp">Wyloguj</button>
      </header>
      <base-card class="" :columns="12">
        <template #default>
          <apartments-list
            :apartmentsList="getAllApartments"
            :text="false"
          ></apartments-list>
        </template>
      </base-card>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApartmentsList from "../components/apartments/ApartmentsList.vue";
export default {
  components: { ApartmentsList },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getAllApartments"]),
  },
  methods: {
    ...mapActions(["fetchAllApartments", "logout"]),
    addNewApartment() {
      this.$router.push("/add");
    },
    logoutFromApp() {
      this.logout();
    },
  },
  async mounted() {
    await this.fetchAllApartments();
    this.apartments = this.getAllApartments;
  },
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";

header {
  text-shadow: $multi;
  h2 {
    text-transform: uppercase;
    color: $primary;
    font-size: 1.75em;
    span {
      text-transform: capitalize;
      font-weight: normal;
      color: white;
    }
  }
}
.cols {
  display: flex;
  justify-content: space-between;
}
.pagination {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: $baseSize * 1.3;
  border-top: $black;
  div {
    &:first-child {
      span {
        &:first-child {
          font-weight: bold;
        }
        margin-right: 1rem;
      }
    }
  }
}
.my-4 {
  display: flex;
  justify-content: center;
  margin-top: 5.5rem;
  margin-bottom: 2rem;
}
.ml-2 {
  margin-left: 1rem;
}
</style>